import { errorFromResponse, notification, notifications } from '~/app/notifications'
import { get, set } from 'lodash'
import { hookDefinitions, hookDispatch } from '~/hooks'
import contentSettings from '@site/content.settings'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import gql from 'graphql-tag'
import jsCookie from 'js-cookie'

export const actions = {
  async ADD_TO_BULK_ORDER({ commit, state }, { product }) {
    const item = state.bulkOrder.find(i => i.product.id === product.id)
    if (item) {
      commit('UPDATE_AMOUNT_IN_BULK_ORDER', { amount: item.amount + 1, product })
    } else {
      commit('ADD_PRODUCT_TO_BULK_ORDER', { amount: 1, product })
    }
  },
  FETCH_GUEST_CART_TOKEN({ commit }) {
    const token = jsCookie.get('X-Inspishop-Guest-Cart')
    if (token) {
      commit('SET_GUEST_CART_TOKEN', token)
    }
  },
  async EMPTY_CART({ commit, state, dispatch, rootGetters }) {
    try {
      const items = state.cartData.items
      const url = `${this.$env.STORE_URL}/api/v1/cart/items`
      const response = await this.$axios.$delete(url)
      await dispatch('SET_CART_DATA', response.data.cart)
      items.forEach(item =>
        eventBus.$emit(eventDefinitions.CART.ITEM.REMOVE, {
          item: item,
        }),
      )
      if (rootGetters['user/userIsLoggedIn']) {
        await dispatch('SET_CREDITS', 0)
      }
      return response.data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async FETCH_CALENDAR_SLOTS({}, { since, shipperId }) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/delivery-times?since=${since}&shipperId=${shipperId}`
      const response = await this.$axios.$get(url)
      return response.data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async SET_CART_DATA({ commit, dispatch, state }, cartData) {
    try {
      if (cartData && cartData.items) {
        cartData = await dispatch('UPDATE_DATA_WITH_VARIANTS', cartData)
        const invalidItems = cartData.items.filter(item => !item.product || !item.shoppable || !item.variant)
        if (invalidItems && invalidItems.length > 0) {
          notification(this.app.i18n.t('globals.invalidAddToCartNotification'), 'danger', { duration: 30000 })
          const promises = []
          invalidItems.forEach(invalidItem => {
            promises.push(this.$axios.$delete(this.$env.STORE_URL + '/api/v1/cart/items/' + invalidItem.id))
          })
          await Promise.all(promises)
          const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/cart')
          const refreshedCartData = await dispatch('UPDATE_DATA_WITH_VARIANTS', response.data)
          commit('SET_CART_DATA', refreshedCartData)
        } else {
          commit('SET_CART_DATA', cartData)
        }
        dispatch('CHECK_SELECTED_GIFTS')
        dispatch('SET_GIFT_PRICES')

        // Refresh shipper and payment objects stored in local storage. There might be different prices after cart change.
        await Promise.all([dispatch('FETCH_SHIPPERS', { force: true }), dispatch('FETCH_PAYMENTS')])
        if (state.input.shipper) {
          commit('SET_CART_INPUT_FIELD', {
            field: 'shipper',
            value: state.shippers.find(shipper => shipper.id === state.input.shipper.id),
          })
        }
        if (state.input.payment) {
          commit('SET_CART_INPUT_FIELD', {
            field: 'payment',
            value: state.payments.find(payment => payment.id === state.input.payment.id),
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  },
  async REMOVE_FROM_CART({ commit, state, dispatch, rootGetters }, item) {
    try {
      const response = await this.$axios.$delete(this.$env.STORE_URL + '/api/v1/cart/items/' + item.id)
      await dispatch('SET_CART_DATA', response.data.cart)
      eventBus.$emit(eventDefinitions.CART.ITEM.REMOVE, {
        item: item,
      })
      if (rootGetters['user/userIsLoggedIn'] && response.data.cart.items && response.data.cart.items.length === 0) {
        await dispatch('SET_CREDITS', 0)
      }
      return response.data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async ADD_TO_CART({ commit, state, dispatch }, { id, type, quantity, parentId, newLine, forCredits, custom }) {
    try {
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/items', {
        id: id,
        type: type || 'Inspishop\\Shop\\Model\\Entities\\Product',
        amount: Number(quantity),
        parent_id: parentId ? Number(parentId) : null,
        newLine: newLine || false,
        credits: forCredits || false,
        custom: custom || [],
      })
      await dispatch('SET_CART_DATA', response.data.cart)
      const addedItem = response.data.cart.items.find(cartItem => cartItem.id === response.data.addedItem.id)
      if (addedItem) {
        response.data.addedItem = addedItem
        eventBus.$emit(eventDefinitions.CART.ITEM.ADD, {
          addedAmount: response.data.changedAmount,
          item: addedItem,
        })
      }
      if (response.meta.custom && response.meta.custom['X-Inspishop-Guest-Cart']) {
        commit('SET_GUEST_CART_TOKEN', response.meta.custom['X-Inspishop-Guest-Cart'])
        dispatch('SET_AXIOS_GUEST_CART_TOKEN')
        return response.data
      }
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async ADD_BATCH_TO_CART({ commit, state, dispatch }, batch) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/cart/items/batch`
      const response = await this.$axios.$post(url, { items: batch })
      if (!state.guestCartToken) {
        const guestCartToken = get(response, 'meta.custom["X-Inspishop-Guest-Cart"]')
        if (guestCartToken) {
          commit('SET_GUEST_CART_TOKEN', guestCartToken)
          dispatch('SET_AXIOS_GUEST_CART_TOKEN')
        }
      }
      await dispatch('FETCH_CART', { forceReload: true })
      return response.data
    } catch (e) {
      if (e.response.status === 500) {
        notifications.info(this.app.i18n.t('globals.batchAddToCart.importInProgress'))
        return
      } else {
        const duration = 3000 * (1 + e.response.data.errors.length)
        const message = e.response.data.errors.map(error => error.title)
        notification(message.join('<br><br>'), 'info', {
          duration: duration,
          timer: true,
        })
        notification(
          this.app.i18n.tc('globals.batchAddToCart.importIncomplete', batch.length - e.response.data.errors.length, {
            countTotal: batch.length,
          }),
          'success',
          {
            duration: duration,
          },
        )
        await dispatch('FETCH_CART', { forceReload: true })
        return
      }
    }
  },
  async SAVE_CART({ commit }, cart) {
    try {
      const url = `${this.$env.STORE_URL}/api/v1/user/shopping-lists`
      const response = await this.$axios.$post(url, cart)
      return response.data
    } catch (e) {
      console.error(e)
      return e.response
    }
  },
  async GET_BRANCHES({ commit }, { shipperId, query, perPage }) {
    try {
      const params = []
      if (shipperId) params.push(`shipper_id=${shipperId}`)
      if (query) params.push(`s=${query}`)
      if (perPage) params.push(`perPage=${perPage}`)

      const response = await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/branches?${params.join('&')}`)
      return response
    } catch (e) {
      console.error(e)
    }
  },
  // TODO: This function has side effects and should not return value
  async UPDATE_DATA_WITH_VARIANTS({ dispatch }, data) {
    try {
      if (data.items) {
        // Get all item ids including nested items
        const productIds = []
        function fetchProductIds(item) {
          productIds.push(item.productId)
          if (item.items) {
            for (let childItem of item.items) {
              fetchProductIds(childItem)
            }
          }
        }
        for (let item of data.items) {
          fetchProductIds(item)
        }

        // Load and distribute product details
        const products = (await dispatch('product/LOAD_PRODUCTS', { ids: productIds }, { root: true })) || []
        function updateItem(item) {
          const product = products.find(product => product.id === item.productId)
          const variant =
            product && typeof product !== 'undefined'
              ? product.variants.find(variant => variant.id === item.variantId)
              : null
          item.shoppable = variant
          item.variant = variant
          item.product = product
          if (item.items) {
            for (let childItem of item.items) {
              updateItem(childItem)
            }
          }
        }
        for (let item of data.items) {
          updateItem(item)
        }
      }
      return data
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_CART({ commit, state, rootState, dispatch }, { forceReload = false }) {
    try {
      if (state.guestCartToken || rootState.auth.token) {
        if (!state.isLoaded || forceReload) {
          const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/cart')
          await dispatch('SET_CART_DATA', response.data)
          await dispatch('SET_CART_INPUT_DEFAULT')
        }
      } else {
        commit('SET_EMPTY_CART')
      }
    } catch (e) {
      console.error(e)
    }
  },
  SET_AXIOS_GUEST_CART_TOKEN({ state }) {
    this.$axios.defaults.headers.common['x-inspishop-guest-cart'] = state.guestCartToken
  },
  async FETCH_SHIPPERS({ commit, state }, { force } = {}) {
    if (!state.shippers.length || force) {
      try {
        const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/cart/shippers')
        commit('SET_SHIPPERS', response.data)

        // Refresh shipper input in cart
        if (state.input.shipper) {
          const freshShipper = state.shippers.find(shipper => shipper.id === state.input.shipper.id)
          commit('SET_CART_INPUT_FIELD', { field: 'shipper', value: freshShipper })
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  async GET_STORE_BRANCH_BY_REMOTE_ID({ commit }, { shipperId, remoteId }) {
    try {
      const response = await this.$axios.$get(
        this.$env.STORE_URL + '/api/v1/shippers/' + shipperId + '/branches/remoteId/' + remoteId,
      )
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_AVAILABLE_GIFTS({ commit }) {
    try {
      const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/order-gifts')
      commit('SET_AVAILABLE_GIFTS', response.data)
    } catch (e) {
      console.error(e)
    }
  },
  async UPDATE_ITEM({ commit, dispatch }, { id, quantity }) {
    try {
      const response = await this.$axios.$patch(this.$env.STORE_URL + '/api/v1/cart/items/' + id, {
        amount: Number(quantity),
      })
      await dispatch('SET_CART_DATA', response.data.cart)
      const updatedItem = response.data.cart.items.find(cartItem => cartItem.id === response.data.updatedItem.id)
      if (updatedItem) {
        response.data.updatedItem = updatedItem
        const changedAmount = response.data.changedAmount
        if (changedAmount > 0) {
          eventBus.$emit(eventDefinitions.CART.ITEM.ADD, {
            addedAmount: response.data.changedAmount,
            item: updatedItem,
          })
        }
        if (changedAmount < 0) {
          eventBus.$emit(eventDefinitions.CART.ITEM.REMOVE, {
            item: updatedItem,
            removedAmount: Math.abs(response.data.changedAmount),
          })
        }
      }
      notifications.info(this.app.i18n.t('globals.cart.updateItem.success'))
      return response.data
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async GET_ORDER_BY_TOKEN({ commit, dispatch }, { token }) {
    try {
      const response = await this.$axios.$get(`${this.$env.STORE_URL}/api/v1/orders/token/${token}`, {
        validateStatus(status) {
          return (status >= 200 && status < 300) || status === 404
        },
      })
      const orderData = await dispatch('UPDATE_DATA_WITH_VARIANTS', response.data)
      return orderData
    } catch (e) {
      console.error(e)
    }
  },
  async FETCH_PAYMENTS({ commit }) {
    try {
      const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/cart/payments')
      commit('SET_PAYMENTS', response.data)
    } catch (e) {
      console.error(e)
    }
  },
  async PLACE_ORDER(actionContext) {
    const { commit, state, dispatch, rootState, rootGetters, getters } = actionContext
    try {
      const name = this.$themeSettings.global.userFullName
        ? state.input.fullName
        : state.input.firstName + ' ' + state.input.lastName
      const shippingName = this.$themeSettings.global.userFullName
        ? state.input.shippingFullName
        : state.input.shippingFirstName + ' ' + state.input.shippingLastName

      const getDefaultAddress = key => {
        const searchKey = key !== 'country_code' ? key : 'countryCode'
        return state.input.shipperBranch
          ? state.input.shipperBranch.address[searchKey]
          : rootState.globals.contact.address[searchKey]
      }
      const setShipperAddress = addressData => {
        for (const val of ['street', 'city', 'postcode', 'country_code']) {
          addressData[val] = getDefaultAddress(val)
        }
      }
      const isRegistration = getters.isRegistration
      const data = {
        name,
        firstName: state.input.firstName,
        lastName: state.input.lastName,
        email: state.input.email,
        phone: state.input.phone,
        billing_address: {
          street: state.input.billingHouseNumber
            ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
            : state.input.billingStreet,
          city: state.input.billingCity,
          postcode: state.input.billingPostcode,
          country_code: state.input.billingCountry,
        },
        business: state.input.isBusiness
          ? {
              business_name: state.input.businessName,
              TIN: state.input.tin,
              VATIN: state.input.vatin,
            }
          : null,
        shipping: state.input.shipToShippingAddress
          ? {
              name: shippingName.replace(' ', '') !== '' ? shippingName : name,
              address: {
                street: state.input.houseNumber
                  ? `${state.input.street} ${state.input.houseNumber}`
                  : state.input.street,
                city: state.input.city,
                postcode: state.input.postcode,
                country_code: state.input.country,
              },
            }
          : {
              name: shippingName.replace(' ', '') !== '' ? shippingName : name,
              address: {
                street: state.input.billingHouseNumber
                  ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
                  : state.input.billingStreet,
                city: state.input.billingCity,
                postcode: state.input.billingPostcode,
                country_code: state.input.billingCountry,
              },
            },
        custom: state.input.custom,
        comment: state.input.note,
        shipper_comment: state.input.note_for_shipper,
        payment_id: state.input.payment ? state.input.payment.id : null,
        shipper_id: state.input.shipper ? state.input.shipper.id : null,
        branch_id: state.input.shipperBranch ? state.input.shipperBranch.id : null,
        terms: true,
        cardRequest: state.input.cardRequest,
        order_gifts: state.selectedGifts.map(g => g.id),
        newsletter: state.input.newsletter,
        heureka_allow: state.input.complianceHeureka,
        zbozicz_allow: state.input.complianceZbozi,
        magazine: state.input.complianceMagazine,
        phone_notification: state.input.complianceSmsMarketing,
        delivery_time_id: state.input.deliveryTimeSlot ? state.input.deliveryTimeSlot.id : null,
        ...(isRegistration
          ? {
              password: state.input.password,
              password_confirmation: state.input.passwordConfirmation,
              registration: true,
            }
          : {}),
      }
      if (state.input.shippingBusinessName && state.input.shippingBusinessName.replace(/\s/g, '').length > 0) {
        if (
          state.input.isBusiness
            ? !!this.$themeSettings.components.PageCheckoutInfo.input.shippingBusinessName.enableWithBusiness
            : true
        ) {
          data.shipping.business_name = state.input.shippingBusinessName
        }
      }
      if (
        state.input.shipToShippingAddress &&
        state.input.shippingPhone &&
        state.input.shippingPhone.replace(/\s/g, '').length > 0
      ) {
        data.shipping.phone = state.input.shippingPhone
      }
      if (state.input.formalTitle) {
        data.custom.formalTitle = state.input.formalTitle
      }
      if (state.input.vatinsvk) {
        data.custom.ICDPH = state.input.vatinsvk
      }
      if (state.input.dateOfBirth) {
        data.custom.dateOfBirth = state.input.dateOfBirth
      }
      if (state.input.companyName) {
        data.custom.companyName = state.input.companyName
      }

      // Fill shipper branch address if custom billing address is disabled
      if (!state.input.billingInfo) {
        setShipperAddress(data.billing_address) // Set default billing address
        if (data.business) {
          data.business = null // Reset business data
        }
      }

      // Fill shipper branch address if address is not required
      if (state.input.shipper && state.input.shipper.requiredAddress === false) {
        setShipperAddress(data.shipping.address) // Set default shipping address
        if (data.shipping.business_name) {
          delete data.shipping.business_name // Remove shipping company name
        }
        if (data.shipping.phone) {
          delete data.shipping.phone // Remove shipping phone number
        }
      }

      if (rootGetters['utm/getUtmParams']) {
        data.utm = rootGetters['utm/getUtmParams']
      }
      await hookDispatch(hookDefinitions.CART.PLACE_ORDER.DATA_PROCESS, { actionContext, data })
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/place', data)
      const orderData = await dispatch('UPDATE_DATA_WITH_VARIANTS', response.data)
      commit('SET_PLACED_ORDER', orderData)
      return response
    } catch (e) {
      console.log('PLACE_ORDER error:', e)
      return e.response
    }
  },
  async SET_CART_INPUT_DEFAULT({ commit, dispatch, state, rootState, rootGetters }) {
    commit('SET_CART_INPUT_FIELD', {
      field: 'formalTitle',
      value: get(rootState, 'user.userData.attributes.custom.formalTitle', null),
    })
    commit('SET_CART_INPUT_FIELD', {
      field: 'dateOfBirth',
      value: get(rootState, 'user.userData.attributes.custom.dateOfBirth', null),
    })
    commit('SET_CART_INPUT_FIELD', { field: 'fullName', value: state.cartData.customer.name })
    commit('SET_CART_INPUT_FIELD', { field: 'firstName', value: state.cartData.customer.firstName })
    commit('SET_CART_INPUT_FIELD', { field: 'lastName', value: state.cartData.customer.lastName })
    commit('SET_CART_INPUT_FIELD', { field: 'companyName', value: '' })
    commit('SET_CART_INPUT_FIELD', { field: 'email', value: state.cartData.customer.email })
    commit('SET_CART_INPUT_FIELD', { field: 'phone', value: state.cartData.customer.phone })
    commit('SET_CART_INPUT_FIELD', {
      field: 'isBusiness',
      value: this.$themeSettings.global.billingInputsAlwaysRequired ? true : !!state.cartData.customer.tin,
    })
    commit('SET_CART_INPUT_FIELD', {
      field: 'newsletter',
      value:
        get(rootState, 'user.isLoaded', null) && get(state, 'cartData.customer.name', null) !== ''
          ? state.cartData.customer.newsletter
          : this.$themeSettings.components.PageCheckoutInfo.newsletter.defaultValue,
    })
    if (state.cartData.customer.businessName) {
      commit('SET_CART_INPUT_FIELD', { field: 'businessName', value: state.cartData.customer.businessName })
    }
    commit('SET_CART_INPUT_FIELD', { field: 'businessTerms', value: false })
    commit('SET_CART_INPUT_FIELD', { field: 'terms', value: false })
    commit('SET_CART_INPUT_FIELD', { field: 'tin', value: state.cartData.customer.tin })
    commit('SET_CART_INPUT_FIELD', { field: 'vatin', value: state.cartData.customer.vatin })
    commit('SET_CART_INPUT_FIELD', {
      field: 'vatinsvk',
      value: get(rootState, 'user.userData.attributes.custom.ICDPH', null),
    })
    commit('SET_CART_INPUT_FIELD', { field: 'street', value: state.cartData.shippingAddress.street })
    commit('SET_CART_INPUT_FIELD', { field: 'city', value: state.cartData.shippingAddress.city })
    if (rootGetters['user/userIsLoggedIn']) {
      commit('SET_CART_INPUT_FIELD', {
        field: 'country',
        value: get(rootState, 'user.userData.address.countryCode', null),
      })
    } else {
      const countryCode = rootState.globals.countries.find(
        country =>
          country.languageCode ===
          rootState.globals.languages.find(language => language.code === this.$i18n.locale).code,
      )
      if (countryCode) {
        commit('SET_CART_INPUT_FIELD', {
          field: 'country',
          value: countryCode.alpha3,
        })
      }
    }
    commit('SET_CART_INPUT_FIELD', { field: 'postcode', value: state.cartData.shippingAddress.postcode })
    commit('SET_CART_INPUT_FIELD', { field: 'differentAddress', value: state.cartData.hasCustomShippingAddress })
    commit('SET_CART_INPUT_FIELD', { field: 'note', value: state.cartData.comment })
    commit('SET_CART_INPUT_FIELD', {
      field: 'shippingBusinessName',
      value: state.cartData.customer.shippingBusinessName,
    })
    commit('SET_CART_INPUT_FIELD', { field: 'billingStreet', value: state.cartData.billingAddress.street })
    commit('SET_CART_INPUT_FIELD', { field: 'billingCity', value: state.cartData.billingAddress.city })
    commit('SET_CART_INPUT_FIELD', { field: 'billingPostcode', value: state.cartData.billingAddress.postcode })
    commit('SET_CART_INPUT_FIELD', { field: 'billingCountry', value: state.cartData.billingAddress.countryCode })

    commit('SET_CART_INPUT_FIELD', { field: 'shippingFullName', value: state.cartData.customer.shippingName })
    commit('SET_CART_INPUT_FIELD', { field: 'shippingFirstName', value: state.cartData.customer.shippingFirstName })
    commit('SET_CART_INPUT_FIELD', { field: 'shippingLastName', value: state.cartData.customer.shippingLastName })

    if (state.cartData.hasCustomShippingAddress) {
      commit('SET_CART_INPUT_FIELD', { field: 'shipToShippingAddress', value: true })
    }

    dispatch('CHECK_FILLED_BILLING_DATA')
  },
  async RESET_CART_INPUT({ commit }) {
    commit('SET_CART_INPUT_FIELD', { field: 'shipper', value: null })
    commit('SET_CART_INPUT_FIELD', { field: 'payment', value: null })
    commit('SET_CART_INPUT_FIELD', { field: 'shipperBranch', value: null })
    commit('SET_CART_INPUT_FIELD', { field: 'deliveryTimeSlot', value: null })
  },
  async RESET_CART_DATA({ commit }) {
    commit('SET_CART_DATA', null)
  },
  async SET_PROMO_CODE({ commit, state, dispatch }, promoCode) {
    try {
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/coupon', {
        couponCode: promoCode,
      })
      await dispatch('SET_CART_DATA', response.data.cart)
    } catch (e) {
      return e.response
    }
  },
  async REMOVE_PROMO_CODE({ commit, state, dispatch }) {
    try {
      const response = await this.$axios.$delete(this.$env.STORE_URL + '/api/v1/cart/coupon')
      await dispatch('SET_CART_DATA', response.data.cart)
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
  async SET_CREDITS({ commit, dispatch, rootState }, credits) {
    if (rootState.globals.settings.functions.loyalty_credits) {
      try {
        const url = `${this.$env.STORE_URL}/api/v1/cart/credits`
        const response = await this.$axios.$post(url, {
          credits: credits,
        })
        await dispatch('SET_CART_DATA', response.data.cart)
      } catch (e) {
        errorFromResponse(e.response)
      }
    }
  },
  CHECK_FILLED_BILLING_DATA({ commit, state }) {
    const {
      billingCity,
      billingHouseNumber,
      billingName,
      billingPostcode,
      billingStreet,
      businessName,
      tin,
      vatin,
      vatinsvk,
    } = state.input
    if (
      billingCity ||
      billingHouseNumber ||
      billingName ||
      billingPostcode ||
      billingStreet ||
      businessName ||
      tin ||
      vatin ||
      vatinsvk
    ) {
      commit('SET_CART_INPUT_FIELD', { field: 'billingInfo', value: true }) // Show billing form if any billing input already filled
      if (businessName || tin || vatin || vatinsvk) {
        commit('SET_CART_INPUT_FIELD', { field: 'isBusiness', value: true }) // Show billing business form part if any business input already filled
      }
    }
  },
  CHECK_SELECTED_GIFTS({ commit, state, rootState }) {
    state.selectedGifts.forEach(selectedGift => {
      if (
        !state.availableGifts.find(availableGift => availableGift.id === selectedGift.id) ||
        (state.isLoaded && selectedGift.price_from - ((state.cartData.prices || {}).discountLimitPriceWithVat || 0) > 0)
      ) {
        commit('REMOVE_GIFT', selectedGift)
      }
    })
  },
  CHECK_SELECTED_PAYMENT({ commit, state }) {
    if (state.input && state.input.payment && !state.payments.find(payment => payment.id === state.input.payment.id)) {
      commit('SET_CART_INPUT_FIELD', { field: 'payment', value: null })
    }
  },
  CHECK_SELECTED_SHIPPER({ commit, state }) {
    if (state.input && state.input.shipper && !state.shippers.find(shipper => shipper.id === state.input.shipper.id)) {
      commit('SET_CART_INPUT_FIELD', { field: 'shipper', value: null })
      commit('SET_CART_INPUT_FIELD', { field: 'shipperBranch', value: null })
    }
  },
  SET_GIFT_PRICES({ commit, state, rootState }) {
    // TODO: move to the back-end

    const coeff = Math.pow(10, rootState.currency.activeCurrency?.decimalPlaces || 0)
    const giftPrice = Math.ceil((contentSettings.giftPrice || 0) * coeff) / coeff

    if (giftPrice <= 0.0) {
      return
    }

    const taxCoeff = state.cartData?.prices?.totalOriginalWithoutTax / state.cartData?.prices?.totalOriginalWithTax
    const giftTotalWithVat = state.selectedGifts.length * giftPrice
    const giftTotalWithoutVat = Math.round(giftTotalWithVat * taxCoeff * coeff) / coeff

    commit('SET_CART_DATA', {
      ...state.cartData,
      prices: {
        ...state.cartData.prices,
        giftsWithTax: giftTotalWithVat,
        giftsWithoutTax: giftTotalWithoutVat,
        totalWithTax: (state.cartData?.prices?.itemsWithTax || 0) + giftTotalWithVat,
        totalWithoutTax: (state.cartData?.prices?.itemsWithoutTax || 0) + giftTotalWithoutVat,
      },
    })
  },
  async VALIDATE_CART_DATA() {
    try {
      await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/validate')
      return true
    } catch (e) {
      errorFromResponse(e.response)
      return false
    }
  },

  async GET_ITEMS_PARAMETERS({}, { items, selectedParameterIds = [] }) {
    try {
      const parameters = []
      const valueIds = []
      // Optionally choose only parameters of interest to be fetched
      let parameterIds = selectedParameterIds

      // Acquire content ids that will be loaded
      items.forEach(item => {
        parameters.push(...(item.product.content.parameters || []))
      })

      if (parameterIds.length === 0) {
        parameterIds = parameters.map(parameter => parameter.entityId)
      }

      parameters.forEach(parameter => {
        if (parameterIds.includes(parameter.entityId) && parameter.values && Array.isArray(parameter.values)) {
          parameter.values.forEach(valueId => {
            valueIds.push(valueId)
          })
        }
      })

      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query($parameterIds: [String], $valueIds: [String]) {
            ParameterValues(filters: { _id: $valueIds }) {
              entities {
                _id
                title
                description_short
                description_long
                link_url
                parameter_value_image {
                  alt
                  path
                  title
                }
                hide_product_detail
              }
            }
            parameter(filters: { _id: $parameterIds }) {
              entities {
                _id
                title
                parameter_type
                parameter_unit
                link_to_virtual_category
                description
                parameter_hide_product_detail
              }
            }
          }
        `,
        variables: {
          parameterIds,
          valueIds,
        },
      })

      // Assign entities to parameters and values
      for (let parameter of parameters) {
        parameter.entity = query.data.parameter.entities.filter(p => p._id === parameter.entityId)[0]
        parameter.valueEntities = []
        if (parameter.values && Array.isArray(parameter.values)) {
          parameter.values.forEach(valueId => {
            const value = query.data.ParameterValues.entities.filter(v => v._id === valueId)[0]
            if (value && value.hide_product_detail !== true) {
              parameter.valueEntities.push(value)
            }
          })
        }
      }

      // Filter empty parameters
      return parameters.filter(
        p =>
          p.entity &&
          p.valueEntities &&
          ((p.entity.parameter_type[0] === 'number' && p.values > 0) || p.valueEntities.length > 0) &&
          !p.entity.parameter_hide_product_detail,
      )
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  isLoaded: false,
  cartData: {},
  conditionsPageSlug: null,
  bulkOrder: [],
  guestCartToken: null,
  input: {
    dateOfBirth: null,
    shipper: null,
    shipperBranch: null,
    payment: null,
    formalTitle: '',
    fullName: '',
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
    shipToShippingAddress: false,
    isBusiness: false,
    differentAddress: false,
    newsletter: false,
    businessName: '',
    businessTerms: false,
    terms: false,
    tin: '',
    vatin: '',
    vatinsvk: '',
    street: '',
    houseNumber: '',
    city: '',
    postcode: '',
    country: null,
    note: '',
    note_for_shipper: '',
    billingInfo: false,
    billingName: '',
    billingStreet: '',
    billingHouseNumber: '',
    billingCity: '',
    billingPostcode: '',
    billingCountry: null,
    cardRequest: false,
    complianceHeureka: false,
    complianceZbozi: false,
    complianceMagazine: false,
    complianceSmsMarketing: false,
    custom: {},
    deliveryTimeSlot: null,
    password: '',
    passwordConfirmation: '',
    isRegistration: false,
    shippingBusinessName: '',
    shippingPhone: '',
    shippingFullName: '',
    shippingFirstName: '',
    shippingLastName: '',
  },
  checkout: {
    currentStep: 0,
    steps: [
      {
        name: 'index',
        path: 'checkout',
      },
      {
        name: 'methods',
        path: 'checkout-methods',
      },
      {
        name: 'info',
        path: 'checkout-info',
      },
    ],
  },
  finalPrice: {
    totalWithoutTax: 0,
    totalWithTax: 0,
  },
  availableGifts: [],
  selectedGifts: [],
  shippers: [],
  fastestShipper: null,
  payments: [],
  placedOrder: {},
})

export const mutations = {
  ADD_GIFT(state, gift) {
    if (!state.selectedGifts.find(g => g.id === gift.id)) {
      state.selectedGifts.push(gift)
    }
  },
  CLEAN_BULK_ORDER(state) {
    state.bulkOrder = []
  },
  ADD_PRODUCT_TO_BULK_ORDER(state, { product, amount }) {
    state.bulkOrder.push({
      amount,
      product,
    })
  },
  UPDATE_AMOUNT_IN_BULK_ORDER(state, { amount, product }) {
    const index = state.bulkOrder.findIndex(i => i.product.id === product.id)
    state.bulkOrder[index].amount = amount
  },
  REMOVE_PRODUCT_FROM_BULK_ORDER(state, { id }) {
    state.bulkOrder = state.bulkOrder.filter(i => i.product.id !== id)
  },
  REMOVE_GIFT(state, gift) {
    const giftToRemove = state.selectedGifts.find(g => g.id === gift.id)
    if (giftToRemove) {
      state.selectedGifts.splice(state.selectedGifts.indexOf(giftToRemove), 1)
    }
  },
  SET_AVAILABLE_GIFTS(state, data) {
    state.availableGifts = data
  },
  SET_BRANCH(state, data) {
    state.input.shipperBranch = data
  },
  SET_CART_DATA(state, data) {
    state.cartData = data
    state.isLoaded = true
  },
  SET_GUEST_CART_TOKEN(state, token) {
    state.guestCartToken = token
    jsCookie.set('X-Inspishop-Guest-Cart', token, { expires: 1 })
  },
  SET_CART_INPUT_FIELD(state, { field, value }) {
    state.input[field] = value
  },
  SET_CART_INPUT_CUSTOM(state, { field, value }) {
    state.input.custom[field] = value
  },
  RESET_CART_INPUT_CUSTOM(state, { field }) {
    delete state.input.custom[field]
  },
  SET_CART_INPUT_SHIPPER(state, shipper) {
    if (state.input.shipper && state.input.shipper.id !== shipper.id) {
      state.input.shipperBranch = null
    }
    state.input.shipper = shipper
    if (state.input.payment && state.input.shipper.blockedPayments.indexOf(state.input.payment.id) !== -1) {
      state.input.payment = null
    }
  },
  SET_EMPTY_CART(state) {
    state.cartData = {}
    state.isLoaded = true
  },
  SET_EMPTY_GIFTS(state) {
    state.selectedGifts = []
  },
  SET_SHIPPERS(state, data) {
    state.shippers = data

    // Set fastest shipper
    state.shippers.forEach(shipper => {
      if (
        shipper.type !== 'PERSONAL' &&
        (!state.fastestShipper ||
          new Date(shipper.delivery.nearestDeliveryDate) < new Date(state.fastestShipper.delivery.nearestDeliveryDate))
      ) {
        state.fastestShipper = shipper
      }
    })
  },
  SET_PAYMENTS(state, data) {
    state.payments = data
  },
  SET_PLACED_ORDER(state, data) {
    state.placedOrder = data
  },
  SET_CHECKOUT_STEP(state, step) {
    if (state.checkout.currentStep >= 0 || state.checkout.currentStep <= state.checkout.steps.length - 1) {
      state.checkout.currentStep = step
    }
  },
  SET_CONDITIONS_PAGE(state, data) {
    state.conditionsPageSlug = data
  },
}

export const getters = {
  freeShippingAt(state, getters, rootState) {
    if (rootState.cart.isLoaded && rootState.cart.cartData) {
      const freeShippingFromCart = get(rootState, 'cart.cartData.prices.freeShippingAt', null)
      return freeShippingFromCart ? freeShippingFromCart : rootState.globals.freeShippingAt
    }
  },
  isRegistration(state) {
    return (
      state.input.isRegistration &&
      state.input.password &&
      state.input.passwordConfirmation &&
      state.input.password === state.input.passwordConfirmation
    )
  },
}
