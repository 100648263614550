export const actions = {
  async FETCH_MENU({ commit, state }, code) {
    if (!state[code]) {
      try {
        const data = await this.$axios.$get(this.$env.CMS_URL + '/menu-object/' + code)
        commit('SET_MENU', {
          code,
          data,
        })
      } catch (e) {
        console.error(e)
      }
    }
  },
  FIND_MENU_ITEMS({ state }, { ids, menu }) {
    const retItems = []
    if (state[menu].menuItems) {
      getRecursive(state[menu].menuItems)
    }

    function getRecursive(children) {
      for (let item of children) {
        if (ids.indexOf(item.id) !== -1 && item.main) {
          retItems.push(item)
        }
        if (item.children) {
          getRecursive(item.children)
        }
      }
    }
    retItems.sort(function(a, b) {
      return ids.indexOf(a.id) - ids.indexOf(b.id)
    })
    return retItems
  },
  async SEARCH_ITEMS({ state, rootState }, { queryParts, location, ids = [] }) {
    const transliterateModule = await import('transliteration')
    const tr = transliterateModule.transliterate
    const results = []
    const expressions = queryParts.map(s => {
      return new RegExp(tr(s).toLowerCase(), 'i')
    })
    const expressionLength = expressions.length

    let items = [...state.mainMenu.menuItems]
    if (this.$themeSettings.global.searchAddsVirtualCategoriesToCategoryResults) {
      items.push(
        ...rootState.search[location].results.virtual_categories.entities.map(category => {
          return {
            id: category._id,
            path: category.page_meta.slug,
            title: category.title,
            main: true,
          }
        }),
      )
    }
    findRecursive(items)

    return results.sort((a, b) => b.score - a.score).map(result => result.item)

    // Fills up results variable
    function findRecursive(children) {
      for (let item of children) {
        if (item.main) {
          const itemTitle = tr(item.title)
          const score = expressions.map(regex => itemTitle.match(regex)).filter(r => !!r).length
          const inIds = ids.includes(String(item.id))
          if (score === expressionLength || inIds) {
            results.push({
              item,
              score,
            })
          }
        }
        if (item.children) {
          findRecursive(item.children)
        }
      }
    }
  },
}

export const state = () => ({
  activeMenuItem: null,
})

export const mutations = {
  SET_ACTIVE_MENU_ITEM(state, menuItem) {
    state.activeMenuItem = menuItem
  },
  SET_MENU(state, { code, data }) {
    state[code] = data
    if (data.menuItems) {
      data.menuItems.forEach(item => setParentRecursive(item, null))
    }
    function setParentRecursive(item, parent) {
      item.parent = parent
      if (item.children) {
        item.children.forEach(c => setParentRecursive(c, item))
      }
    }
  },
}
