export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    iconSize: {
      type: String,
      default: 'default',
    },
    link: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      return this.link ? 'a' : 'span'
    },
  },
}
