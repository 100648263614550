<script>
import { get, isObject, pickBy } from 'lodash'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  mixins: [ImageUrlMixin],
  data() {
    return {
      metaInfoChanged: false,
      metaInfoEvents: [this.sendViewEventPage],
      bodyClass: null,
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.metaInfoChanged = false
      },
    },
  },
  mounted() {
    this.forceHeadChange() // Must to be, because of first init
  },
  methods: {
    forceHeadChange() {
      this.bodyClass = ''
    },
    getSeoHead(settings) {
      const { data, yamlKey, noindex, nofollow, follow } = settings || {}
      const seoData =
        data ||
        this.article ||
        this.author ||
        this.branch ||
        this.category ||
        (isObject(this.page) && this.page) ||
        this.product ||
        this.tag ||
        this.term ||
        this.getSeoMetaFromYaml(yamlKey) ||
        this.headerConfiguration ||
        {}
      return {
        bodyAttrs: {
          ...(this.bodyClass !== null && this.bodyClass !== false ? { class: this.bodyClass } : {}),
        },
        changed: () => {
          this.sendViewEvents()
        },
        title: this.getSeoTitle(seoData),
        meta: [
          ...this.getSeoMetaUrl(),
          ...this.getSeoMetaMain(seoData),
          ...this.getSeoMetaDescription(seoData),
          ...this.getSeoMetaFacebookDomainVerification(),
          ...this.getSeoMetaGoogleSiteVarification(),
          ...this.getSeoMetaImage(seoData),
          ...this.getSeoMetaType(),
          ...this.getSeoMetaKeywords(seoData),
          ...this.getSeoMetaRobots({ data: seoData, noindex, nofollow, follow }),
          ...this.getSeoMetaSiteName(),
          ...this.getSeoMetaTitle(seoData),
          ...this.getSeoMetaCustom(seoData),
        ],
        link: [
          ...this.getFavicons(),
          ...this.getLCPElements(),
          ...this.getFontsLocal(),
          ...this.getFontsGoogle(),
          ...this.getSeoLinkCanonical(),
          ...this.alternateLinks(),
        ],
        ...this.getSeoMetaAttrsMain(),
        ...this.getSeoMetaAttrsCustom(seoData),
      }
    },
    alternateLinks() {
      if (this.$contentSettings.seo.alternateLinks) {
        const alternatives = this.$store.state.locale.alternativeUrls
        return alternatives.map(item => ({
          rel: 'alternate',
          hreflang:
            this.$store.state.locale.sameDomain && item.language === this.$i18n.locale ? 'x-default' : item.language,
          href: item.url,
        }))
      } else {
        return []
      }
    },
    canonicalLinkUrl({ path } = {}) {
      const url = this.createFullPathWithoutBlacklistedParameters({ path })
      return this.makeAbsoluteUrl(url)
    },
    createFullPathWithoutBlacklistedParameters({
      path = null,
      additionalBlacklist = [],
      additionalParameters = {},
    } = {}) {
      if (!path) {
        path = this.$route.path
      }
      const blacklist = this.$themeSettings.global.blacklistedCanonicalParameters
      blacklist.push(...additionalBlacklist)

      const allParameters = Object.assign({}, this.$route.query, additionalParameters)
      let amendedQuery = pickBy(allParameters, function(value, key) {
        return !blacklist.includes(key)
      })
      const queryArray = []
      for (let key in amendedQuery) {
        if (amendedQuery.hasOwnProperty(key)) {
          queryArray.push(key + '=' + amendedQuery[key])
        }
      }
      const encodedQuery = encodeURI(queryArray.join('&'))
      return encodedQuery ? `${path}?${encodedQuery}` : path
    },
    getFallbackMetaBySlug(key) {
      return get(this.$store, 'state.globals.slugMetadata[' + key + ']') || ''
    },
    getFavicons() {
      return [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }]
    },
    getFontsGoogle() {
      return [
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: 'anonymous' },
        {
          rel: 'stylesheet',
          href:
            'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Source+Sans+Pro:wght@300;400;600;700;900&family=Francois+One&display=swap',
        },
      ]
    },
    getFontsLocal() {
      return [
        { rel: 'preload', href: '/fonts/NunitoSans-Regular.ttf', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/NunitoSans-Bold.ttf', as: 'font', crossorigin: 'anonymous' },
      ]
    },
    getMetaBySlug(key) {
      const cleanSlug = slug => {
        let output = slug
        if (output.startsWith('/')) {
          output = output.slice(1)
        }
        if (output.endsWith('/')) {
          output = output.slice(0, -1)
        }
        return output
      }
      const cleanPath = cleanSlug(get(this.$route, 'path'))
      const compareSlug = itemSlug => {
        return cleanSlug(itemSlug) == cleanPath
      }
      const slugs_metadata = get(this.$store.state, 'globals.slugMetadata.slugs_metadata')
      const data = (slugs_metadata || []).find(i => compareSlug(i.slug))
      return get(data, key)
    },
    getPageMetaData(data) {
      let output = {}
      const translationVariant = this.$store.state.locale.translationVariant
      const contentLocale = this.$i18n.locale + (translationVariant ? `-${translationVariant}` : '')
      const pageMeta = get(data, 'content.page_meta') || get(data, 'page_meta', {})
      for (const key in pageMeta) {
        if (typeof get(pageMeta[key], contentLocale) !== 'undefined') {
          output[key] = get(pageMeta[key], contentLocale)
        } else {
          output[key] = pageMeta[key]
        }
      }
      return output
    },
    getLCPElements() {
      return [
        // {
        //   rel: 'preload',
        //   as: 'image',
        //   href: 'https://storefront/bg.webp',
        //   media: '(min-width: 768px)'
        // }
      ]
    },
    getSeoLinkCanonical() {
      return [
        {
          rel: 'canonical',
          href: this.makeAbsoluteUrl(this.$route.path),
        },
      ]
    },
    getSeoMetaAttrsCustom(data) {
      return {}
    },
    getSeoMetaAttrsMain() {
      return {
        headAttrs: {
          itemscope: '',
          itemtype: 'http://schema.org/WebSite',
        },
      }
    },
    getSeoMetaCustom(data) {
      return []
    },
    getSeoMetaDescription(data) {
      const content =
        get(this.getPageMetaData(data), 'seo_description') ||
        get(data, 'seo_description') ||
        this.getMetaBySlug('description') ||
        get(data, 'description') ||
        this.getFallbackMetaBySlug('description') ||
        this.$store.state.globals.meta.seo_description ||
        ''
      return content
        ? [{ hid: 'description', name: 'description', content }, { property: 'og:description', content }]
        : []
    },
    getSeoMetaFacebookDomainVerification() {
      const content = this.$store.state.globals.settings.integrations.facebook_domain_verification
      return content ? [{ hid: 'facebook-domain-verification', name: 'facebook-domain-verification', content }] : []
    },
    getSeoMetaFromYaml(yamlKey) {
      if (!yamlKey) {
        return false
      }
      const keys = ['description', 'keywords', 'title']
      let output = {}
      const website = this.$t('globals.website')
      keys.forEach(key => {
        const value = this.$t(yamlKey + '.seo.' + key, { website })
        if (value && !value.startsWith(yamlKey)) {
          output[key] = value
        } else {
          const fallbackValue = this.$t(yamlKey + '.' + key)
          if (fallbackValue && !fallbackValue.startsWith(yamlKey)) {
            output[key] = fallbackValue
          }
        }
      })
      return Object.keys(output).length ? output : false
    },
    getSeoMetaGoogleSiteVarification() {
      const content = this.$store.state.globals.settings.integrations.google_site_verification
      return content ? [{ hid: 'google-site-verification', name: 'google-site-verification', content }] : []
    },
    getSeoMetaImage(data) {
      const content = this.imageUrl({
        src:
          get(this.getPageMetaData(data), 'share_image') ||
          this.getMetaBySlug('image[0].path') ||
          this.$store.state.globals.meta.seo_image,
      })
      return content
        ? [
            {
              property: 'og:image',
              content,
            },
          ]
        : []
    },
    getSeoMetaKeywords(data) {
      const content =
        get(this.getPageMetaData(data), 'seo_keywords') ||
        get(data, 'seo_keywords') ||
        this.getMetaBySlug('keywords') ||
        get(data, 'keywords') ||
        this.getFallbackMetaBySlug('keywords') ||
        this.$store.state.globals.meta.seo_keywords ||
        ''
      return content ? [{ hid: 'keywords', name: 'keywords', content }] : []
    },
    getSeoMetaMain() {
      return [
        { itemprop: 'url', content: this.$env.STOREFRONT_URL },
        { itemprop: 'name', content: this.getSeoShortTitle() },
      ]
    },
    getSeoMetaRobots(settings) {
      const { data, noindex, nofollow, follow } = settings || {}
      const metaNoindex = noindex || get(this.getPageMetaData(data), 'meta_noindex') || get(data, 'meta_noindex')
      const metaNofollow = nofollow || get(this.getPageMetaData(data), 'meta_nofollow') || get(data, 'meta_nofollow')
      const metaFollow = follow || get(this.getPageMetaData(data), 'meta_follow') || get(data, 'meta_follow')
      return this.robotsMetaTag({ noindex: metaNoindex, nofollow: metaNofollow, follow: metaFollow })
    },
    getSeoMetaSiteName() {
      const content = this.getFallbackMetaBySlug('name') || this.$store.state.globals.meta.seo_title || ''
      return content ? [{ property: 'og:site_name', content }] : []
    },
    getSeoMetaUrl() {
      return [{ property: 'og:url', content: this.makeAbsoluteUrl(this.$route.path) }]
    },
    getSeoMetaTitle(data) {
      const content = this.getSeoTitle(data)
      return [
        {
          property: 'og:title',
          content,
        },
      ]
    },
    getSeoMetaType() {
      const content = 'website'
      return [
        {
          property: 'og:type',
          content,
        },
      ]
    },
    getSeoShortTitle() {
      return this.getFallbackMetaBySlug('name') || this.$store.state.globals.meta.seo_short_title
    },
    getSeoTitle(data) {
      const title =
        get(data, 'seo_title') ||
        get(data, 'page_meta.seo_title') ||
        this.getMetaBySlug('title') ||
        get(data, 'title') ||
        this.getFallbackMetaBySlug('title')
      return title ? this.seoTitleSuffix(title) : this.$store.state.globals.meta.seo_title || ''
    },
    makeAbsoluteUrl(url) {
      const language = this.$i18n.locale
      let absoluteURL = url
      // Add domain, if URL doesn't already contain domain
      if (url.indexOf(/^(http:|https:)/g) === -1) {
        const { domain } = this.$store.state.locale.canonicalDomains.find(item => item.language === language) || {}
        if (domain) {
          // Fix for double lang in URL (ex. domain/en/en/slug
          if (absoluteURL.startsWith(`/${language}`) && domain.indexOf(`/${language}`) !== -1) {
            absoluteURL = absoluteURL.replace(`/${language}`, '')
          }
          absoluteURL = domain + absoluteURL
        }
      }
      return absoluteURL.replace(/([^:])[\/]{2,}/g, '$1/') // replace more then one slash with one slash (expt. http(s)://)
    },
    registerViewEvents(events) {
      if (typeof events === 'object' && events instanceof Array) {
        this.metaInfoEvents = this.metaInfoEvents.concat(events)
      }
    },
    robotsMetaTag(settings) {
      const { noindex, nofollow, follow } = settings || {}
      let values = []
      if (noindex) {
        values.push('noindex')
      } else {
        values.push('index')
      }
      if (nofollow) {
        values.push('nofollow')
      } else if (follow || !nofollow) {
        values.push('follow')
      }
      return [{ hid: 'robots', name: 'robots', content: values.join(', ') }]
    },
    sendViewEvent(event, data) {
      eventBus.$emit(event, data)
    },
    sendViewEventPage() {
      this.sendViewEvent(eventDefinitions.SITE.PAGE_VIEW, {
        page_route_name: this.$route.name,
        page_url: this.$route.fullPath,
      })
    },
    sendViewEvents() {
      if (!this.metaInfoChanged) {
        this.metaInfoChanged = true
        for (const event of this.metaInfoEvents) {
          event()
        }
      }
    },
    seoTitleSuffix(title) {
      return `${title} - ${this.getSeoShortTitle()}`
    },
  },
}
</script>
