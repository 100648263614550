import { merge } from 'lodash'
import themeSettings from '~/themes/blog/settings'

const overloadSettings = {
  sitesUrl: {
    entry: {
      cs: 'https://mimi.nobilis.cz',
      sk: 'https://mimi.nobilis-tilia.sk',
    },
  },
  components: {
    ArticleBox: {
      articleInfo: {
        author: {
          enable: true,
          icon: 'person',
        },
        category: {
          enable: true,
          icon: 'folder',
          maxCount: 2,
        },
        date: {
          enable: true,
          icon: 'calendar',
        },
        enable: false,
      },
      image: {
        crop: true,
        enable: true,
        height: 220, // Change SASSs variable $ArticleBox-image-height
        position: 'top', // top, left
        width: 700, // Change SASSs variable $ArticleBox-image-width
      },
      linkReadMore: {
        button: 'link', // link, primary or secondary
        enable: false,
      },
      perex: {
        enable: true,
      },
      showIcons: false,
    },
    ArticleList: {
      perLine: {
        xs: 1,
        lg: 3,
      },
      perPage: 6,
    },
    AuthorBox: {
      image: {
        crop: true,
        height: 80,
        width: 80,
      },
    },
    ContentHeader: {
      categoryDropdown: {
        enable: true,
      },
      image: {
        required: false,
      },
      showBreadcrumbsFirstItemInContent: true,
      tagDropdown: {
        enable: true,
      },
    },
    LanguageSwitcher: {
      type: 'flag',
      dropdownTriggerType: ['hover', 'click', 'focus'],
      enable: true,
      showActiveLanguage: true,
      shortSelectedName: true,
      useAlternativeLinks: true,
      useCanonicalLinks: false,
      useHomepageLinks: false,
      useStorefrontUrl: false,
    },
    PageArticleDetail: {
      articleInfo: {
        author: {
          showLink: true,
        },
      },
      backToArticles: {
        enable: false,
        button: 'light', // link, primary or secondary
      },
      category: {
        classPrefix: 'master-category-',
      },
      footer: {
        authorStyle: 'AuthorBox', // `AuthorBox`, `inline`
        enable: true,
      },
      header: {
        enable: true,
      },
      sidebar: {
        enable: false,
      },
      showIcons: false,
    },
    PageArticleList: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: false,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleListAuthor: {
      header: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
      sidebar: {
        enable: false,
      },
      image: {
        crop: false,
        enable: true,
        height: 250, // Change SASSs variable $ArticleBox-image-height
        position: 'top', // top, left
        width: 250,
      },
      perPage: 6,
    },
    PageArticleListCategory: {
      actionsOnBottom: true,
      header: {
        enable: true,
        enableBreadcrumbsFirstItem: true,
        fullViewportWidthWrapper: false,
      },
      masterCategoryPrefix: 'master-category master-category-',
      sidebar: {
        enable: false,
      },
    },
    PageCategoryList: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: false,
        position: 'right', // `left`, `right`
      },
    },
    PageProductDetail: {
      accessoryProducts: {
        perLine: {
          xs: 1,
          md: 2,
          xl: 4,
        },
      },
    },
    SearchInput: {
      enableDropdown: false,
    },
  },
  siteCode: 'mimi',
}

export default merge(themeSettings, overloadSettings)
