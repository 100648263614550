import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppTitle,
    AppImage,
    Wysiwyg,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'landing_banner',
  },
  mounted() {
    console.log(this.block)
  },
  query() {
    return `
      ... on block_landing_banner {
        type
        uuid
        background_image_mobile {
          path
          alt
        }
        background_image {
          path
          alt
        }
        title
        description
        list {
          ... on landing_block_banner_list_item {
            text
          }
        }
        padding_top
        padding_bottom
        images {
          ... on landing_block_background_image {
            isWallpaper
            positionX
            positionY
            width
            height
            offsetX
            offsetY
            image {
              path
              alt
            }
          }
        }
        divider {
          ... on landing_block_divider {
            height
            width
            rotation
            title
            description
            image {
              path
              alt
            }
          }
        }
        background_color
      }
    `
  },
}
