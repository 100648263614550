<script>
export default {
  computed: {
    cartItemsCount() {
      const cartItems = this.$store.state.cart.cartData.items
      if (cartItems) {
        const amount = this.$store.state.cart.cartData.items.reduce(
          (acc, item) => acc + (item.amount > 1 ? Math.floor(item.amount) : Math.ceil(item.amount)),
          0,
        )
        return amount
      } else {
        return 0
      }
    },
    compareItemsCount() {
      return this.$store.state.compare.productIds.length
    },
    highestCountAcrossInfoComponents() {
      return Math.max(this.cartItemsCount, this.compareItemsCount, this.wishListItemsCount)
    },
    wishListItemsCount() {
      return this.$store.state.user.wishList ? this.$store.state.user.wishList.items.length : 0
    },
  },
}
</script>
