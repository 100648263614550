import ComponentLoadingMixin from '~/mixins/ComponentLoadingMixin'
import HeadingLevel from '~/mixins/HeadingLevel'
import ProductBox from '@theme/components/product/ProductBox'
import ProductTeaser from '@theme/components/product/ProductTeaser'

export default {
  mixins: [ComponentLoadingMixin, HeadingLevel],
  components: {
    ProductBox,
    ProductTeaser,
  },
  props: {
    forceEnable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'teaser',
    },
    placement: {
      type: String,
      required: true,
    },
    size: {
      type: Number | Object,
      default: 4,
    },
    columnClasses: String,
    context: Object,
  },
  data() {
    return {
      products: [],
    }
  },
  async mounted() {
    if (this.$themeSettings.components.ProductsRecommended.quarticonEnable) {
      this.products = await this.$store.dispatch('recommendation/LOAD_PRODUCTS', {
        context: this.context,
        placement: this.placement,
        size: this.maxSize,
      })
    }
    if (
      this.products.length < 1 &&
      this.$themeSettings.components.ProductsRecommended.categoryProductsEnable &&
      this.context.product
    ) {
      this.products = await this.$store.dispatch('product/LOAD_PRODUCTS', {
        categoryIds: [this.context.product.categoryId],
        random: this.maxSize,
      })
    }
    this.componentLoaded()
  },
  computed: {
    maxSize() {
      return Object.keys(this.size).length > 0
        ? Object.values(this.size).reduce((max, value) => (value > max ? value : max), 0)
        : this.size
    },
    minSize() {
      return Object.keys(this.size).length > 0
        ? Object.values(this.size).reduce((max, value) => (value < max ? value : max), Infinity)
        : this.size
    },
    productComponent() {
      if (this.type === 'box') return ProductBox
      if (this.type === 'teaser') return ProductTeaser
    },
  },
  methods: {
    columnClassesProcess(index) {
      let classes = this.columnClasses

      if (Object.keys(this.size).length > 0) {
        if (index + 1 > this.minSize) {
          classes += ' d-none'
        }
        for (let [breakpoint, value] of Object.entries(this.size)) {
          if (breakpoint !== 'default') {
            if (index + 1 <= value) {
              classes += ` d-${breakpoint}-block`
            }
          }
        }
      }

      return classes
    },
  },
}
