<script>
import { get } from 'lodash'

export default {
  mounted() {
    const getElementHeight = (className, varName) => {
      const element = document.getElementsByClassName(className)
      const { offsetHeight } = get(element, '[0]') || {}
      if (offsetHeight) {
        const varValue = `${offsetHeight}px`
        if (varValue !== document.documentElement.style.getPropertyValue('--' + varName)) {
          document.documentElement.style.setProperty('--' + varName, varValue)
        }
      }
    }

    const getViewportHeight = () => {
      const height = get(window, 'innerHeight')
      if (height) {
        document.documentElement.style.setProperty('--viewport-inner-height-px', `${height}px`)
      }
    }

    const getHeights = () => {
      setTimeout(() => {
        getViewportHeight()
        getElementHeight('HeaderMain', 'header-main-height')
        getElementHeight('HeaderMain--stickyOnMobile', 'header-main-mobile-height')
        getElementHeight('TopBanner', 'top-banner-height')
        getElementHeight('LanguageBanner', 'language-banner-height')
      }, 500)
    }

    getHeights()
    window.addEventListener('orientationchange', getHeights)
  },
}
</script>
