import gql from 'graphql-tag'
import linkEmbedded from '~/queries/fragments/linkEmbedded'

export default {
  all(type) {
    return gql`
      ${linkEmbedded.base}
      fragment catalogDataFragment_${type} on ${type} {
        content
        text_below_products
        category_banner {
          path
        }
        page_meta {
          seo_description
          seo_keywords
          seo_title
          share_image
        }
        faq_questions_reference {
          entity {
            ... on faq_question {
              _id
              title
              content
            }
          }
        }
        banner_small_reference {
          entity {
            ... on banner_small {
              description
              title
              weight
              customer_group_enabled
              link {
                ...linkEmbeddedFragmentsBase
              }
              main_image {
                alt
                path
                title
              }
            }
          }
        }
        top_content_reference {
          entity {
            ... on article {
              _id
              title
              short_title
              page_meta { slug }
            }
          }
        }
        content_reference {
          entity {
            ... on article {
              _id
              title
              short_title
              page_meta { slug }
            }
          }
        }
        category_video {
          ... on video_embedded {
            title
            video_url
          }
        }
        banner_catalog {
          ... on banner_catalog_embedded {
            banner_catalog_position
            banner_catalog_reference {
              entity {
                ... on banner_catalog {
                  _id
                  image {
                    path
                    alt
                  }
                  main_image_translatable {
                    path
                    alt
                  }
                  title
                  url
                  customer_group_enabled
                  time_limited
                  time_limit_start
                  time_limit_end
                }
              }
            }
          }
        }
      }
    `
  },
}
