import { actions as parentActions } from '~/sites/nobilis/store/homepage'
import gql from 'graphql-tag'

const actions = {
  ...parentActions,
  async LOAD_HOMEPAGE_CONFIGURATION() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const response = await client.query({
        query: gql`
          query {
            configuration_mimi_homepage {
              sections {
                ... on mimi_homepage_section {
                  background_image {
                    alt
                    path
                    title
                  }
                  button {
                    ... on link_embedded {
                      title
                      link_type
                      custom_link
                      category_link {
                        entity {
                          ... on StoreCategory {
                            data
                          }
                        }
                      }
                      article_category_link {
                        entity {
                          ... on article_category {
                            page_meta {
                              slug
                            }
                            title
                            perex
                            master_category {
                              entity {
                                ... on article_category {
                                  _id
                                  title
                                  perex
                                  page_meta {
                                    slug
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  description
                  main_image {
                    alt
                    path
                    title
                  }
                  title
                }
              }
              newsletters {
                ... on mimi_newsletter_section {
                  title
                  description
                  background_image {
                    alt
                    path
                    title
                  }
                  newsletter_blocks {
                    ... on mimi_newsletter_block {
                      title
                      description
                    }
                  }
                }
              }
            }
          }
        `,
      })
      const { configuration_mimi_homepage } = response.data
      return configuration_mimi_homepage
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions }
