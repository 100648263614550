import contentSettings from '~/sites/shop/content.settings'

const settings = {
  ...contentSettings,
  masterCategories: {
    '60a76e9bead0160017ffdd69': 'mothers',
    '60a76ef5ead0160017ffdd6b': 'childrens',
  },
  slugMetadataSite: 'mimi',
}

export default settings
