<script>
// For more info please see https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
export default {
  data() {
    return {
      activeBreakpoint: '',
    }
  },
  mounted() {
    this.refreshActiveBreakpoint()
    window.addEventListener('resize', this.refreshActiveBreakpoint)
  },
  destroy() {
    window.removeEventListener('resize', this.refreshActiveBreakpoint)
  },
  methods: {
    refreshActiveBreakpoint() {
      this.activeBreakpoint = window
        .getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content')
        .replace(/\"/g, '')
    },
  },
}
</script>
