import ImportBreakpointsIntoJavascript from '~/mixins/ImportBreakpointsIntoJavascript'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'
import VueScrollTo from 'vue-scrollto'
import _ from 'lodash'

export default {
  mixins: [ImportBreakpointsIntoJavascript, UtilityGridMixin],
  props: {
    itemsPerLine: {
      type: Object,
      default() {
        return {}
      },
    },
    showPartOfNextProduct: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.Carousel.showPartOfNextProduct
      },
    },
    step: {
      type: Number,
      default: 1,
    },
    speed: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      activeIndex: 0,
      shakeItem: false,
      carouselHasContent: false,
    }
  },
  created() {
    this.scrollThrottle = _.throttle(this.scroll, this.speed * 1.2)
  },
  mounted() {
    this.options = {
      cancelable: true,
      container: this.$refs.wrapper,
      x: true,
      y: false,
    }
    this.init()
    window.addEventListener('resize', this.resized)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  },
  computed: {
    childNodes() {
      return _.get(this.$refs, 'row.childNodes')
    },
    isEnd() {
      return this.wrapperWidth + this.itemPadding + this.activeIndex * this.itemWidth >= this.rowWidth
    },
    isStart() {
      return this.activeIndex === 0
    },
    paginationItemsCount() {
      if (!this.$themeSettings.components.Carousel.showPagination) {
        return 0
      }
      const itemsPerLine = this.fillMissingBreakpointsToSizes(this.itemsPerLine)
      const itemPerLine = itemsPerLine[this.activeBreakpoint] || 1
      const { length: childItems } = this.childNodes || []
      return itemPerLine < childItems ? childItems - itemPerLine + 1 : 0
    },
  },
  methods: {
    next() {
      if (!this.isEnd) {
        this.activeIndex += this.step
        this.scrollThrottle()
      } else {
        this.shakeEdgeItem()
      }
    },
    prev() {
      if (!this.isStart) {
        this.activeIndex -= this.step
        if (this.activeIndex < 0) {
          this.activeIndex = 0
        }
      } else {
        this.shakeEdgeItem()
      }
      this.scrollThrottle()
    },
    init() {
      if (this.childNodes && this.childNodes.length > 0) {
        this.carouselHasContent = true
        this.itemWidth = this.$refs.row.childNodes[0].offsetWidth
        this.itemPadding = parseFloat(window.getComputedStyle(this.$refs.row.childNodes[0]).paddingLeft)
        this.wrapperWidth = this.$refs.wrapper.offsetWidth
        this.rowWidth = this.$refs.wrapper.scrollWidth
      }
    },
    resized() {
      this.init()
      this.scroll()
    },
    scroll() {
      const childNodes = _.get(this.$refs, 'row.childNodes')
      if (childNodes && childNodes.length > 0) {
        const offset = this.activeIndex * this.itemWidth + this.itemPadding
        VueScrollTo.scrollTo(childNodes[0], this.speed, {
          ...this.options,
          offset,
        })
      }
    },
    shakeEdgeItem() {
      this.shakeItem = true
      setTimeout(() => {
        this.shakeItem = false
      }, 1000)
    },
    swipeHandler(direction) {
      if (direction === 'left') {
        this.next()
      }
      if (direction === 'right') {
        this.prev()
      }
    },
  },
}
