import { errorFromResponse, notifications } from '@/app/notifications'
import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

import NewsletterForm from '~/themes/nobilis/components/shop/NewsletterForm'

export default {
  extends: NewsletterForm,
  props: {
    lhiId: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: '',
    },
    checkForBlock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    BtnTxt() {
      if (!this.checkForBlock) {
        return this.$t('NewsletterForm.button')
      } else {
        return this.$t('NewsletterForm.buttonBlock')
      }
    },
  },
  methods: {
    async submit() {
      if (this.lhiId) {
        window.lhi.plugins.Popup.open(this.lhiId)
      } else {
        this.startLoading()
        if (this.email) {
          const response = await this.$store.dispatch('user/SUBSCRIBE_NEWSLETTER', {
            email: this.email,
            ...(this.source
              ? {
                  source: this.source,
                }
              : {}),
          })
          if (response) {
            if (response.data && response.data.errors && response.data.errors[0]) {
              if (response.status === 401) {
                notifications.success(response.data.errors[0].title)
              } else {
                errorFromResponse(response)
              }
            } else {
              notifications.success(this.$t('NewsletterForm.notifications.success'))
              eventBus.$emit(eventDefinitions.NEWSLETTER.SUBSCRIBED, {
                email: this.email,
              })
              this.email = ''
            }
          }
        } else {
          notifications.danger(this.$t('NewsletterForm.notifications.empty'))
        }
        this.stopLoading()
      }
    },
  },
}
