<script>
import { orderBy } from 'lodash'

export default {
  methods: {
    getMasterCategories() {
      return orderBy(this.$store.state.article.categories.filter(c => c.master_category.length === 0), 'weight', 'desc')
    },
    getCategoriesForMasterCategory(category) {
      return orderBy(
        this.$store.state.article.categories.filter(
          c => c.master_category.length > 0 && c.master_category[0].entity._id === category._id,
        ),
        'weight',
        'desc',
      )
    },
  },
}
</script>
